// Import Core Clean Blog SCSS
@import "../assets/vendor/startbootstrap-clean-blog/scss/clean-blog.scss";
//@import "../assets/vendor/bootstrap/css/bootstrap.min.css";

.bg-footer {
  background-color: #17233d;
  margin-bottom: 0;
}

.ue-logo-container {
  max-width: 100%;
  padding: 2.5px;
  background-color: #fff;

  > .logo-ue {
    max-width: 100%;
    max-height: 75px;
  }
}

#loading {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: fixed;
  display: block;
  z-index: 999999;
  background-color: #17233D;
}

#loading-image {
  	transform: scale(1);
	animation: pulse 0.6s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.85) rotate(0deg);
	}

	25% {
		transform: scale(0.925) rotate(5deg);
	}

	50% {
		transform: scale(1)  rotate(0deg);;
	}

	75% {
		transform: scale(0.925) rotate(-5deg);
	}
	100% {
		transform: scale(0.85)  rotate(0deg);
	}
}
