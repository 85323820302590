// Styling for the footer
footer {
  padding: 2rem 0rem;
  .list-inline {
    margin: 0;
    padding: 0;
  }
  .copyright {
    font-size: 14px;
    margin: 0;
    text-align: right;
    @include sans-serif-font;
  }
  hr {
    border-color: $faded-white-60;
  }
}
