
.home-container {
  overflow: hidden;

  @media (min-width: 992px) {

      &:before {
      display: inline-block;
      content: '\200B';
      background-repeat: no-repeat;
      background-image: url("/assets/img/home/bg--deco1.svg");
      background-size: contain;
      right: 0;
      top: 175px;
      width: 728px;
      max-width: 100%;
      height: 867px;
      position: absolute;
    }
    }
  @media (max-width: 991px) {

      &:before {
      display: inline-block;
      content: '\200B';
      background-repeat: no-repeat;
      background-image: url("/assets/img/home/bg--deco1.svg");
      background-size: cover;
      right: 0;
      top: 200px;
      width: 300px;
      max-width: 100%;
      height: 567px;
      position: absolute;
    }
  }
}
.services-container {
  padding-top: 3rem;
  @media (min-width: 992px) {
      &:before {
      display: inline-block;
      content: ' ';
      background-repeat: no-repeat;
      background-image: url("/assets/img/home/bg--deco1.svg");
      right: 0;
      top: 320px;
      width: 500px;
      max-width: 100%;
      height: 867px;
      position: absolute;
    }
  }
  @media (max-width: 991px) {
    &:before {
      display: inline-block;
      content: ' ';
      background-repeat: no-repeat;
      background-image: url("/assets/img/home/bg--deco1.svg");
      right: 0;
      top: 220px;
      width: 500px;
      max-width: 45%;
      height: 867px;
      position: absolute;
    }
  }
}
.projects-container {
  padding-top: 3rem;
  @media (min-width: 992px) {
      &:before {
      display: inline-block;
      content: ' ';
      background-repeat: no-repeat;
      background-image: url("/assets/img/home/bg--deco1.svg");
      background-size: cover;
      right: 0;
      top: 350px;
      width: 500px;
      max-width: 100%;
      height: 867px;
      position: absolute;
    }
  }
  @media (max-width: 991px) {
    &:before {
      display: inline-block;
      content: ' ';
      background-repeat: no-repeat;
      background-image: url("/assets/img/home/bg--deco1.svg");
      background-size: cover;
      right: 0;
      top: 50px;
      width: 500px;
      max-width: 45%;
      height: 867px;
      position: absolute;
    }
  }
}
.about-container {
  padding-top: 3rem;
  @media (min-width: 992px) {
      &:before {
      display: inline-block;
      content: ' ';
      background-repeat: no-repeat;
      background-image: url("/assets/img/about/bg-deco.svg");
      right: 0;
      top: 230px;
      width: 700px;
      max-width: 50%;
      height: 867px;
      position: absolute;
    }
  }
  @media (max-width: 991px) {
    &:before {
      display: inline-block;
      content: ' ';
      background-repeat: no-repeat;
      background-image: url("/assets/img/about/bg-deco.svg");
      background-size: cover;
      right: 0px;
      top: 50px;
      width: 400px;
      max-width: 60%;
      height: 800px;
      position: absolute;
    }
  }
}
.vehicles-container {
  padding-top: 3rem;
  @media (min-width: 992px) {
      &:before {
      display: inline-block;
      content: '';
      background-repeat: no-repeat;
      background-image: url("/assets/img/about/bg-deco.svg");
      left: 0;
      transform: scaleX(-1);
      top: 1330px;
      width: 680px;
      max-width: 50%;
      height: 867px;
      position: absolute;
    }
  }
}
.after-vehicles-container {
  position: relative;
  @media (min-width: 992px) {
      &:before {
      display: inline-block;
      content: ' ';
      background-repeat: no-repeat;
      background-image: url("/assets/img/about/bg-deco.svg");
      right: 0;
        transform: scaleY(-1);
      top: 1300px;
      width: 500px;
      max-width: 25%;
      height: 967px;
      position: absolute;
    }
  }
}
.about-container-double {
  padding-top: 3rem;
  @media (max-width: 991px) {
    &:before {
      display: inline-block;
      content: ' ';
      background-repeat: no-repeat;
      background-image: url("/assets/img/about/bg-deco.svg");
      background-size: cover;
      right: 0px;
      top: 50px;
      width: 400px;
      max-width: 60%;
      height: 800px;
      position: absolute;
    }
  }
}
#team-photo{
  @media (min-width: 992px) {
    background-image: url("/assets/img/about/bg--deco3.svg");
    background-size: cover;
    background-position-y: -5rem;
    background-repeat: no-repeat;
  }
  @media (max-width: 991px) {
    background-image: url("/assets/img/about/bg--deco3.svg");
    background-size: cover;
    background-position-y: -3rem;
    background-repeat: no-repeat;
  }
}
.full-width-about-container{
  @media (min-width: 992px) {
    &:after {
      display: inline-block;
      content: '';
      background-repeat: no-repeat;
      background-image: url("/assets/img/about/bg-deco.svg");
      background-size: contain;

      right: 0;
      top: -270px;
      width: 36%;
      height: 1067px;
      position: absolute;
    }
    &:before {
      display: inline-block;
      content: ' ';
      background-repeat: no-repeat;
      background-image: url("/assets/img/about/bg--deco1-2.svg");
      transform: scaleX(-1) rotate(-2deg);
      left: -250px;
      bottom: 100px;
      width: 35%;
      height: 867px;
      position: absolute;
    }
  }
}
.join-us-container {
  padding-top: 3rem;
  @media (min-width: 992px) {
      &:after {
      display: inline-block;
      content: ' ';
      background-repeat: no-repeat;
      background-image: url("/assets/img/about/bg-deco.svg");
      right: 0;
      top: 430px;
      width: 700px;
      max-width: 100%;
      height: 867px;
      position: absolute;
    }
  }
}


.work-container {
  padding-top: 3rem;
  &:after {
    display: inline-block;
    content: ' ';
    background-repeat: no-repeat;
    background-image: url("/assets/img/about/bg-deco.svg");
    right: 0;
    top: 230px;
    width: 700px;
    max-width: 100%;
    height: 867px;
    position: absolute;
  }
}