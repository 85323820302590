.section-header{
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 80px;
  font-family: 'Roboto', sans-serif;
}

.text-werin-blue{
  color: $werin-blue;
}

.werin-normal-font{
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 28px;
}

.werin-tiny-font{
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 26px;
}

.werin-large-font{
  @extend .werin-normal-font;
  font-size: 24px;
  line-height: 32px;
}
.werin-step-font{
  @extend .werin-normal-font;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
}
.werin-huge-font{
  @extend .werin-normal-font;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 38px;
}

.werin-small-font{
  @extend .werin-normal-font;
  font-size: 14px;
  line-height: 24px;
}

.werin-image-overlay-font{
  @extend .werin-normal-font;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
}
.werin-page-header{
  font-family: 'Roboto', sans-serif;
  font-size: 48px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 58px;
  @media only screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 50px;
  }
}

.werin-small-header{
  color: #1D1D1F;
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
}

.werin-home-header{
  font-family: 'Roboto', sans-serif;
  font-size: 72px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 80px;
  @media only screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 50px;
  }
}

.werin-small-home-header{
  font-family: 'Roboto', sans-serif;
  color: #98C9FE;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
}

.teammate-name {
  height: 60px;
  width: 206px;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 20.64px;
}