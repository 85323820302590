// Bootstrap overrides for this template

a {
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}
.btn {
  @include sans-serif-font;
}

.btn-primary {
  background-color: $primary;
  border-color: $primary;
  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: darken($primary, 7.5) !important;
    border-color: darken($primary, 7.5) !important;
  }
}

.btn-lg {
  font-size: 16px;
  padding: 25px 35px;
}

.btn-transparent {
  color: $faded-white-60;
  &:hover {
    color: $light;
  }
  &:focus {
    color: $light;
  }
  &:active {
    color: $light;
  }
  span {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}

.text-faded {
  color: $lightfade;
}

.bg-footer {
  hr {
    border-color: $lightfade;
    opacity: 0.4;
  }
  a:not(.dropdown-item) {
    color: $lightfade;
    &:hover {
      text-decoration: none;
      color: $white;
    }
  }
}

#mainNav .navbar-nav > li.nav-item {
  margin: 0 5px;
}

.navbar-menu {
  color: $white;
  background-color: unset !important;
  backdrop-filter: brightness(0.5);
  border-radius: 15px;
  > a {
    color: $white;
  }
}
#mainNav .navbar-nav > li.nav-item > a {
  padding: 8px 20px;
  color: #fff;
  &.btn {
    border-radius: 50px;
    font-weight: 400;
    &:hover {
      background-color: $blue;
      color: $white;
    }
    &:active {
      background-color: $blue;
      color: $white;
    }
    &:focus {
      background-color: $blue;
      color: $white;
    }
  }
}
#mainNav .navbar-nav > li.nav-item > div > button {
  font-weight: 600;
  font-size: 1.125rem;
  letter-spacing: 1px;
  padding: 8px 20px;
  color: #fff;
  width: 100%;
  &.btn {
    border-radius: 50px;
    font-weight: 400;
    &:hover {
      background-color: $blue;
      color: $white;
    }
    &:active {
      background-color: $blue;
      color: $white;
    }
    &:focus {
      background-color: $blue;
      color: $white;
    }
  }
}
.blue-header {
  color: $lightblue;
  font-weight: normal;
}

.btn-outline-transparent {
  color: $white;
  border-radius: 50px;
  padding: 8px 20px;
  font-weight: 400;
  &:hover {
    border: 1px solid $faded-white-80;
    color: $white;
  }
  &:active {
    border: 1px solid $faded-white-80;
    color: $white;
  }
  &:focus {
    border: 1px solid $faded-white-80;
    color: $white;
  }
}

.btn-cta {
  @extend .btn-primary;
  border-radius: 50px;
  padding: 8px 20px;
  font-weight: 400;
}

.page-header {
  font-weight: bold;
  font-size: 5rem;
  color: $white;
  line-height: 1;
}

.service-card-col {
  max-width: 433px;
  padding: 0 !important;

  .service-card {
    background-color: unset;
    margin-bottom: 10px;
    margin-right: 10px;
    border: 0;
    border-radius: 8px;

    .service-title {
      color: $white;
      line-height: 1.25;
      font-size: 1.5rem;
      font-weight: bold;
      padding-top: 4.2rem;
      position: absolute;
      bottom: 32px;
      width: 95%;
      &:hover {
        text-decoration: none;
      }
    }
    .card_img {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      padding: 30px;
      border-radius: 8px;
      &:before {
        content: " ";
        position: absolute;
        top: 0px;
        left: 0px;
        height: 240px;
        width: 413px;
        background: #17233d;
        opacity: 0;
        border-radius: 8px;
        transition: 0.3s all;
      }
      &:hover:before {
        opacity: 0.6;
      }
    }
  }
  @media (min-width: 481px) {
    .service-card {
      background-color: unset;
      margin-bottom: 10px;
      margin-right: 10px;
      border: 0;
      height: 240px;
      width: 413px;
      border-radius: 8px;

      .service-title {
        color: $white;
        line-height: 1.25;
        font-size: 1.5rem;
        font-weight: bold;
        padding-top: 4.2rem;
        position: absolute;
        bottom: 32px;
        width: 95%;
        &:hover {
          text-decoration: none;
        }
      }
      .card_img {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding: 30px;
        &:before {
          content: " ";
          position: absolute;
          top: 0px;
          left: 0px;
          height: 240px;
          width: 413px;
          background: #17233d;
          opacity: 0;
          border-radius: 8px;
          transition: 0.3s all;
        }
        &:hover:before {
          opacity: 0.6;
        }
      }
    }
  }
  @media (max-width: 480px) {
    .service-card {
      background-color: unset;
      margin-bottom: 10px;
      margin-right: 10px;
      border: 0;
      height: 200px;
      width: 300px;
      border-radius: 8px;

      .service-title {
        color: $white;
        line-height: 1.25;
        font-size: 1.25rem;
        font-weight: bold;
        padding-top: 4.2rem;
        position: absolute;
        bottom: 18px;
        width: 95%;
        &:hover {
          text-decoration: none;
        }
      }
      .card_img {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 8px;
        padding: 20px;
        &:before {
          content: " ";
          position: absolute;
          top: 0px;
          left: 0px;
          height: 200px;
          width: 300px;
          background: #17233d;
          opacity: 0;
          border-radius: 8px;
          transition: 0.3s all;
        }
        &:hover:before {
          opacity: 0.6;
        }
      }
    }
  }
}

.realized-section {
  max-width: 100%;

  .image-description {
    background-color: white;

    position: absolute;
    bottom: 0;
    left: 0;
    text-align: left;
    padding: 40px 50px;
    @media only screen and (max-width: 1280px) {
      position: relative;
    }
  }
}

.realized-section-home {
  position: relative;
  @extend .realized-section;
}

.image-overlay {
  @media (min-width: 992px) {
    &:after {
      display: inline-block;
      content: "";
      background-image: url("/assets/img/home/realized/bg-deco.svg");
      background-size: 100% 366px;
      background-position-y: top;
      background-repeat: no-repeat;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: 466px;
      position: absolute;
      @media only screen and (max-width: 1280px) {
        height: 244px;
      }
    }
  }
}
.team-section-about {
  @extend .realized-section;
}

.lightgray-section {
  background-color: $sectiongrey;
}

.lightgreen-section {
  background-color: $sectiongreen;
}
.testimonial-avatar {
  width: 3rem;
  border-radius: 50%;
}

.testimonial-section {
  @extend .lightgray-section;
  width: 100%;
  min-height: 350px;
  padding: 3rem;

  .testimonial-avatar {
    width: 3rem;
    float: right;
  }
}
.testimonial-section {
  &::before {
    background-image: url("/assets/img/home/testimonial/img-quote.svg");
    display: inline-block;
    content: " ";
    background-repeat: no-repeat;
    left: 15%;
    top: 15%;
    width: 100%;
    max-width: 100%;
    height: 466px;
    position: absolute;
  }
}
.hww-section {
  background-color: $sectiongrey;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-image: url("/assets/img/home/hww/bg-deco.svg");
  background-position-y: 10rem;
  background-repeat: no-repeat;
}

.arrow-btn {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("/assets/img/home/hww/i-arrow.svg");
  background-repeat: no-repeat;
  &:hover {
    filter: contrast(2);
    cursor: pointer;
  }
}

.werin-list {
  padding: 1.5rem;
  list-style: url("/assets/img/projects/i-check.svg");
  > li {
    padding-left: 1rem;
  }
}

.werin-control-prev-icon,
.werin-control-next-icon {
  display: inline-block;
  width: 64px;
  height: 64px;
  background: 50% / 100% 100% no-repeat;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.3));
}
.werin-control-prev-icon {
  background-image: url("/assets/img/projects/btn-left.svg");
}
.werin-control-next-icon {
  background-image: url("/assets/img/projects/btn-right.svg");
}

.werin-control-prev,
.werin-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex; // 1. allow flex styles
  align-items: center; // 2. vertically center contents
  width: 90px;
  color: $carousel-control-color;
  text-align: center;
  opacity: $carousel-control-hover-opacity;
  @include transition($carousel-control-transition);

  @include hover-focus() {
    color: $carousel-control-color;
    text-decoration: none;
    outline: 0;
    opacity: 1;
  }
}

.werin-control-prev {
  left: 0;
  justify-content: left;
}
.werin-control-next {
  right: 0;
  justify-content: right;
}

.team-info {
  position: absolute;
  bottom: 0;
  margin-left: 20px;
  margin-bottom: 40px;
}
.team-info-mail {
  &:hover {
    filter: contrast(2);
  }
}

.teammate-info {
  margin-top: 20px;
  width: 90%;
  font-size: 14px;
  line-height: 1.25;
}
.teammate-img {
  -webkit-transition: -webkit-filter 200ms linear;
  &.active {
    filter: brightness(0.5) drop-shadow(0 0.25rem 0.25rem rgba(0, 0, 0, 0.3));
  }
}

.joboffer-toggler {
  border-radius: 50px;
  color: $primary;
  border: 0;
  padding: 8px 20px;
  font-weight: 400;
  font-weight: bold;

  &:hover {
    color: $werin-darkblue;
  }
}

.joboffer-apply {
  border-radius: 50px;
  padding: 8px 20px;
  font-weight: 400;
  color: $werin-blue !important;
  &:hover {
    color: $white !important;
  }
}

.btn-werin-text {
  color: $primary !important;
  padding: 5px;
  &:hover {
    color: $werin-darkblue !important;
  }
  > img {
    margin-left: 5px;
  }
  @extend .joboffer-toggler;
}

.step {
  background-image: url("/assets/img/work/i-steps.svg");
  width: 43px;
  height: 49px;
  line-height: 50px;
  padding: 0 15px;
  background-size: cover;
  background-repeat: no-repeat;
}

a {
  &.btn {
    text-decoration: none;
  }
}

.service-col {
  &:hover {
    text-decoration: none;
  }
}

.werin-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23007AFF' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.werin-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23007AFF' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.dropdown-item {
  &:hover {
    background-color: rgba(17, 23, 61, 1);
    color: $white;
  }
}

@media only screen and (max-width: 991px) {
  .navbar-nav {
    height: 100vh;
  }
  #wtms-image {
    order: 4;
    padding-top: 2rem;
  }
}
@media only screen and (max-width: 1199px) and (min-width: 992px) {
  #wtms-image-file {
    position: absolute;
    bottom: 0;
  }
}
@media only screen and (min-width: 992px) {
  .nav-item > .dropdown:hover > .dropdown-menu {
    display: block;
  }

  .nav-item > .dropdown > .dropdown-menu > .dropdown-menu-arrow {
    padding-top: 1rem;
    top: -27px;
    left: 25%;
    width: 0;
    height: 0;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-width: 8px 8px;
      border-style: solid;
      border-color: transparent;
      z-index: 1001;
      bottom: -18px;
      right: -8px;
      border-bottom-color: rgba(0, 0, 0, 0.5);
    }
    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 85px;
      height: 8px;
      border-width: 8px 8px;
      border-style: solid;
      border-color: transparent;
      z-index: 1001;
      bottom: -18px;
      left: -38px;
    }
  }
  .nav-item > .dropdown > .dropdown-menu .dropdown-item {
    padding: 0.5rem 2rem;
  }
}

@media (min-width: 992px) {
  .current {
    &::after {
      color: $white;
      display: block;
      content: "—";
      font-weight: bolder;
      font-size: 24px;
      position: relative;
      bottom: 25px;
      width: 100%;
      text-align: center;
      padding: 8px 20px;
      margin-bottom: -55px;
      pointer-events: none;
    }
  }
}
@media (max-width: 991px) {
  .mobile-order-1 {
    order: 1;
  }
  .mobile-order-2 {
    order: 2;
  }
  .mobile-order-3 {
    order: 3;
  }
  .nav-link {
    text-align: left;
  }
  .navbar-nav {
    padding-top: 2rem;
  }
  .navbar-menu {
    border: 0;
    color: $white;
    padding: 1rem 2rem;
    background-color: transparent !important;
    backdrop-filter: unset;
    border-radius: 15px;
    > a {
      color: $white;
    }
  }
  .navbar-collapse {
    &.collapsing {
      -webkit-transition: none !important;
      transition: none !important;
      display: none !important;
    }
  }
}

@media (max-width: 991px) {
  .mobile-pt {
    padding-top: 1rem;
  }
  .mobile-pb {
    padding-bottom: 1rem;
  }
  .visible-lg {
    display: none !important;
  }
  .visible-sm {
    display: block;
  }
}
@media (min-width: 992px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
  .visible-lg {
    display: block !important;
  }
  .visible-sm {
    display: none;
  }
}

.visible-sm {
  > .fa {
    margin-left: 0.75rem;
    font-size: 0.85rem;
  }
  &[aria-expanded="true"] {
    > .fa-chevron-down {
      display: none;
    }
  }
  &[aria-expanded="false"] {
    > .fa-chevron-up {
      display: none;
    }
  }
}

.navbar-brand {
  padding: 0 !important;
  margin: 0 !important;
}
.nav-select {
  flex: 1 1 auto;
  text-align: center;
  font-size: 0.75rem !important;
  @media (min-width: 992px) {
    border-left: 2px solid rgba(255, 255, 255, 0.5);
  }
  @media (max-width: 991px) {
    margin: 0 5px;
  }
}
#language_menu {
  padding: 10px 20px 6px 20px;

  border-radius: 50px !important;
  @media (max-width: 991px) {
    width: 100%;
    text-align: right;
    > .fa-chevron-down,
    .fa-chevron-up {
      margin-left: 0.75rem;
    }
  }

  &[aria-expanded="true"] {
    > .fa-chevron-down {
      display: none;
    }
  }
  &[aria-expanded="false"] {
    > .fa-chevron-up {
      display: none;
    }
  }
}

#mobile-menu-toggler {
  border: 0 !important;
  > #mobile-menu-icon {
    font-size: 2rem;
  }
  &:hover {
    border: 0 !important;
  }
  &:active {
    border: 0 !important;
  }
}

.link-col {
  &:hover {
    color: $werin-blue !important;
    transition: color 2s;
    text-decoration: none;
  }
}
.grayscale {
  filter: grayscale(1);
}