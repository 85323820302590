.transparent-bg {
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  background: transparent;
}
// Styling for the navbar
#mainNav {
  //position: absolute;
  @include sans-serif-font;
  .navbar-brand {
    font-weight: 800;
    color: $gray-800;
    img {
      max-height: 94px;
    }
  }
  .navbar-toggler {
    font-size: 12px;
    font-weight: 800;
    padding: 13px;
    text-transform: uppercase;
    color: $gray-800;
  }
  .navbar-nav {
    > li.nav-item {
      > a {
        //font-size: 12px;
        font-weight: 600;
        font-size: 1.125rem;
        letter-spacing: 1px;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    border-bottom: 1px solid transparent;
    background: transparent;
    backdrop-filter: unset;
    .navbar-brand {
      padding: 10px 20px;
      color: $white;
      &:focus,
      &:hover {
        color: fade-out($white, .2);
      }
    }
    .navbar-nav {
      > li.nav-item {
        > a {
          padding: 10px 20px;
          color: $white;
          &:focus,
          &:hover {
            color: fade-out($white, .2);
          }
        }
      }
    }
    -webkit-transition: background-color 0.2s;
    -moz-transition: background-color 0.2s;
    transition: all 0.2s ease-in-out;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
  }
  @media only screen and (max-width: 991px) {

    &.position-fixed {
      background-color: #17233D;
    }
  }
}
.scrolled-down{
  transform:translateY(-100%);
  transition: all 0.2s ease-in-out;
  visibility: collapse;
  -webkit-animation:fadeout 0.2s linear;
  > div > .container > .navbar-brand {
    visibility: hidden;
  }


}
.scrolled-up{
  transform:translateY(0);
  transition: all 0.2s ease-in-out;
  background: #17233D !important;
  -webkit-animation:fadein 0.2s linear;
  > div > .container > .navbar-brand {
    visibility: visible;
  }
}

@-webkit-keyframes fadeout{
  0%{opacity:1;visibility:visible;}
  99%{opacity:0;}
  100%{opacity:0;visibility: hidden;}
}
@-webkit-keyframes fadein{
  0%{opacity:0;visibility:visible;}
  100%{opacity:1;}
}
